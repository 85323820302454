/* --------------------------- Аdvantages section START ---------------------------------- */

@media screen and (min-width: 992px) {
	.advantages-section .container {
	    max-width: 1200px;
	}
}
@media screen and (min-width: 1400px) {
	.advantages-section .container {
	    max-width: 1830px;
	}
}
.advantages-section {
	position: relative;
}
.advantages-section .advantages {
    border-radius: 15px;
    background-color: #fdf5d1;
    -webkit-box-shadow: 0px 17px 54px 0px rgb(22 22 22 / 8%);
            box-shadow: 0px 17px 54px 0px rgb(22 22 22 / 8%);
    padding: 10px;
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: -60px;
}
.advantages-section .advantages__inner {
	border-width: 2px;
    border-color: #fece00;
    border-style: dotted;
    border-radius: 10px;
    padding: 40px 40px 10px;
}
.advantages-section .advantages__items {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: distribute;
        justify-content: space-around;
}
.advantages-section .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0 20px;
    max-width: 480px;
    width: 50%;
    margin-bottom: 30px;
}
.advantages-section .img {
	width: 70px;
    height: 70px;
    margin-right: 20px;
}
.advantages-section .info {
    width: calc(100% - 70px - 20px);
}
.advantages-section .info p {
	font-size: 15px;
    font-weight: 400;
    line-height: 1.6;
    color: #444444;
}
.advantages-section .info p.title {
	font-size: 18px;
    font-weight: 700;
    margin-bottom: 4px;
    line-height: 1.4;
    color: #444444;
}

@media screen and (max-width: 991px) {
	.advantages-section .advantages__inner.inner-start {
	    overflow-x: auto;
	    overflow-y: hidden;
	}
	.advantages-section .advantages__inner {
	    padding: 30px 30px 0;
	}
	.advantages-section .advantages__items {
	    -ms-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	    width: 200%;
	}
}

@media screen and (max-width: 767px) {
	.advantages-section .advantages__inner {
	    padding: 30px;
	}
	.advantages-section .advantages__items .item {
		margin-bottom: 0;
    	max-width: unset;
	}
	.advantages-section .info p {
	    word-break: break-word;
	}
	.advantages-section .info p.title {
	    font-size: 25px;
	}
}

@media screen and (max-width: 575px) {
	.advantages-section .advantages__inner {
	    padding: 20px 0 20px;
	}
	.advantages-section .img {
	    width: 50px;
    	height: 50px;
	}
	.advantages-section .info {
	    width: calc(100% - 50px - 20px);
	}
	.advantages-section .info p.title {
	    font-size: 20px;
	}
}

/* --------------------------- Аdvantages section END ---------------------------------- */