/* --------------------------- Section map START ---------------------------------- */

.section-map {
    position: relative;
    height: 860px;
}
.section-map .results {
    position: relative;
    z-index: 2;
    width: 760px;
    height: 100%;
    padding: 30px 0 30px 90px;
    background-color: white;
    -webkit-box-shadow: 0 8px 43px 0 rgb(21 21 21 / 10%);
            box-shadow: 0 8px 43px 0 rgb(21 21 21 / 10%);
    overflow: scroll;
}
.section-map .results .heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 20px;
}
.section-map .results .heading h3 {
    font-family: Baloo;
    font-weight: 500;
    font-size: 32px;
    line-height: 1.2;
    color: #444444;
}
.section-map .results .heading, 
.section-map .results .results__items{
    padding-right: 30px;
}
.section-map .results__items>.item {
    margin-bottom: 30px;
    border-width: 2px;
    border-color: #8074f2;
    border-style: dotted;
    border-radius: 10px;
    padding: 30px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.section-map .results__items>.item:last-child {
    margin-bottom: 0;
}
.section-map .info-window .img, 
.section-map .results__items>.item .img {
    width: 215px;
    height: auto;
    padding: 2px;
    border-width: 2px;
    border-color: rgba(128, 116, 242, 0.4);
    border-style: dotted;
    border-radius: 10px;
    background-color: white;
    -webkit-box-shadow: 0 5px 57px 0 rgb(22 22 22 / 12%);
            box-shadow: 0 5px 57px 0 rgb(22 22 22 / 12%);
    overflow: hidden;
    max-height: 230px;
}
.section-map .results__items>.item .img, 
.section-map .results__items>.item .info h4:hover {
    cursor: pointer;
}
.section-map .info-window .img img, 
.section-map .results__items>.item .img img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 6px;
}
.section-map .results__items>.item .info {
    width: calc(100% - 215px);
    padding-left: 30px;
}
.section-map .results__items>.item .info h4 {
    font-family: Baloo;
    color: #444444;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
}
.section-map .results .results__items .rating{
    color: #8074f2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
}
.section-map .rating>img {
    margin-right: 8px;
    margin-right: 8px;
    max-width: 22px;
}
.section-map .rating ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.section-map .rating ul li {
    cursor: pointer;
    margin-right: 3px;
}
.section-map .rating ul li img {
    display: block;
}
.section-map .results .results__items .rating>span {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #444444;
    margin-left: 5px;
}
.section-map .contact-details {
    padding: 20px 0;
}
.section-map .contact-details p {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    color: #444444;
    line-height: 1.4;
    margin-bottom: 10px;
}
.section-map .contact-details i {
    display: inline-block;
    min-width: 28px;
    position: absolute;
    left: 0;
    top: 0;
}
.section-map .contact-details a {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    color: #444444;
    text-decoration: none;
}
.section-map .open_btn {
    cursor: pointer;
    border-width: 1px;
    border-color: rgba(128, 116, 242, 0.4);
    border-style: solid;
    border-radius: 5px;
    width: 100%;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0 20px;
    position: relative;
}
.section-map .results__items>.item .info .open_btn {
    margin-bottom: 10px;
}
.section-map .open_btn p {
    font-family: Baloo;
    font-size: 12px;
    text-transform: uppercase;
    color: #444444;
    line-height: 1.4;
}
.section-map .open_btn:after {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    content: "";
    width: 9px;
    height: 7px;
    top: 50%;
    margin-top: -3px;
    right: 20px;
    background: url(../../img/select-down.png) center center no-repeat;
}
.section-map .slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    max-width: 100%
}
.section-map .bug {
    font-size: 12px;
    color: #444444;
    display: block;
    margin-top: 30px;
}
.section-map #result--cliniks .bug {
    margin-top: 20px;
}
.section-map .bug a{
    font-size: inherit;
    font-weight: 700;
    text-decoration: underline;
    color: #8074f2;
}
.section-map .bug a:hover {
    text-decoration: none;
}
.section-map .calendar-slider {
    display: none;
}
.section-map .calendar-slider.opened {
    display: block;
}
.section-map button {
    cursor: pointer;
}
.section-map .calendar-slider .prev, 
.section-map .calendar-slider .next {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: absolute;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 29px 0px rgb(22 22 22 / 13%);
            box-shadow: 0px 0px 29px 0px rgb(22 22 22 / 13%);
    border: none;
    background-color: #fff;
    top: 0;
    margin: 0;
    z-index: 50;
    padding: 0;
}
.section-map .calendar-slider .prev {
    left: 0;
}
.section-map .calendar-slider .next {
    left: 44px;
}
.section-map .calendar-slider .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.section-map .slick-slider .calendar-slider .slick-track, 
.section-map .slick-slider .calendar-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.section-map .slick-slider .calendar-slider .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.section-map .slick-slider .calendar-slider .item {
    outline: none;
}
.section-map .slick-slider .healthy-food, 
.section-map .slick-slider .calendar-slider .item {
    display: none;
}
.section-map .slick-slider .healthy-food.show, 
.section-map .slick-slider .calendar-slider .item.show {
    display: block;
}
.section-map .slick-slider .calendar-slider .item .month {
    padding-left: 90px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 36px;
    margin-bottom: 12px;
}
.section-map .slick-slider .calendar-slider .item .month span {
    font-size: 12px;
}
.section-map .slick-slider .calendar-slider .item .days {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.section-map .slick-slider .calendar-slider .item .days .day {
    width: 54px;
    margin-right: 12px;
}
.section-map .slick-slider .calendar-slider .item .days .day>* {
    margin-bottom: 5px;
}
.section-map .slick-slider .calendar-slider .item .days .day .status {
    display: block;
    width: 51px;
    height: 3px;
    background-color: #8074f2;
    border-radius: 3px;
}
.section-map .slick-slider .calendar-slider .item .days .day p {
    font-size: 12px;
}
.section-map .slick-slider .calendar-slider .item .days .day p.number {
    font-weight: 700;
    font-size: 16px;
    color: #8074f2;
}
.section-map .slick-slider .calendar-slider .item .days .day .time_from {
    font-size: 12px;
    font-weight: 700;
}
.section-map .calendar-slider .item .days .day .time_to {
    font-size: 12px;
    font-weight: 700;
}
.section-map .calendar-slider .bug {
    font-size: 12px;
    color: #444444;
    display: block;
    margin-top: 20px;
}
.section-map .calendar-slider .bug.bug a {
    font-size: 18px;
    font-weight: 700;
    text-decoration: underline;
    color: #8074f2;
    line-height: 1.4;
}
.section-map #map{
    left: 760px;
    width: calc(100% - 760px);
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
}
.section-map .btn-white {
    border-radius: 5px;
    background-color: white;
    -webkit-box-shadow: 0 4px 11px 0 rgb(14 14 14 / 14%);
            box-shadow: 0 4px 11px 0 rgb(14 14 14 / 14%);
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: Baloo;
    font-size: 15px;
    color: #8074f2;
    text-transform: uppercase;
    width: 100%;
    max-width: 160px;
    height: 40px;
    padding: 0 10px;
    line-height: 1;
}
.section-map .btn_map {
    display: none;
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -80px;
    z-index: 1000;
}
.section-map .btn-white svg {
    display: block;
    width: 9.75px;
    height: 14px;
    margin-right: 8px;
    fill: #8074f2;
}
.section-map .btn_results {
    display: none;
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -80px;
    z-index: 1000;
}
.section-map .btn-white img {
    margin-right: 8px;
}


.leaflet-pane .leaflet-popup-content {
    margin: 15px;
}
.info-window {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    max-width: 200px;
    width: 100%;
}
.info-window .img {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    margin-bottom: 14px;
}
.info-window h5 {
    text-align: left;
    font-family: Baloo;
    color: #444;
    font-size: 16px;
    line-height: 1.15;
    margin-bottom: 4px;
}
.info-window h5 > *{
    color: #444;
}
.info-window .rating > span {
    display: block;
    margin-left: 7px;
    font-weight: 700;
    color: #444;
}
#map .contact-details {
    padding: 24px 0 5px;
    max-width: 200px;
    width: 100%;
}
.info-window .rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
    color: #0078A8;
}
body .leaflet-container a.leaflet-popup-close-button {
    padding: 2px 0 0;
    width: 20px;
    height: 20px;
    font-size: 20px;
}
.leaflet-popup-content .contact-details p, 
.leaflet-popup-content .contact-details a {
    margin: 0 0 10px;
}
.leaflet-popup-content .contact-details a{
    color: #0078A8;
}

@media screen and (max-width: 1399px) {
    .section-map .results {
        width: 480px;
        padding-left: 30px;
    }
    .section-map .results__items>.item .img {
        margin-bottom: 20px
    }
    .section-map .results__items>.item .info {
        width: 100%;
        padding-left: 0
    }
    .section-map #result--cliniks .bug {
        margin-top: 0;
    }
}

@media screen and (max-width: 1399px) and (min-width: 992px) {
    .section-map #map {
        left: 480px;
        width: calc(100% - 480px);
    }
}

@media screen and (max-width: 991px) {
    .section-map {
        height: auto;
        padding: 0;
        padding-bottom: 50px;
    }
    .section-map .results {
        width: calc(100% - 30px);
        margin: 15px auto 0;
        border-radius: 10px;
        overflow: auto;
        padding: 30px;
    }
    .section-map .results .heading, 
    .section-map .results .results__items {
        padding-right: 0;
    }
    .section-map .results .heading h3{
        font-size: 30px;
    }
    .section-map .results .heading, 
    .section-map .results .results__items {
        padding-right: 0;
    }
    .section-map #map{
        left: 0;
        width: 100%;
    }
    .section-map #map {
        position: fixed !important;
        display: none;
        z-index: 1000;
        height: 100vh;
    }
    .section-map .btn_map{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}

@media screen and (max-width: 767px){
    .section-map .results .results__items .rating {
        word-break: break-all;
    }
    .section-map .contact-details p {
        word-break: break-word;
    }
    .section-map .contact-details a {
        word-break: break-all;
    }
    .section-map .open_btn p {
        word-break: break-word;
        font-size: 16px;
    }
    .section-map #result--cliniks .bug {
        word-break: break-word;
    }
    .section-map .bug a{
        word-break: break-word;
    }
}

@media screen and (max-width: 767px) and (orientation: portrait) {
    .section-map .results__items>.item .img {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 575px) {
    .section-map .results .heading h3{
        font-size: 22px;
    }
    .section-map .results__items>.item .info h4{
        font-size: 18px;
        text-align: center;
    }
    .section-map .results .results__items .rating {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .section-map .contact-details p {
        font-size: 14px;
    }
    .section-map .contact-details a {
        font-size: 14px;
    }
}

/* --------------------------- Section map END ---------------------------------- */