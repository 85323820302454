/* --------------------------- About section START ---------------------------------- */

@media screen and (min-width: 992px) {
	.about-section .container {
	    max-width: 1200px;
	}
}
@media screen and (min-width: 1400px) {
	.about-section .container {
	    max-width: 1830px;
	}
}
.about-section {
    position: relative;
    padding: 50px 0;
    background: url(../../img/bg-question-about.png) center center no-repeat #f4f3fe;
    background-size: auto;
}
.clinics-finder-content .about-section {
	background: url(../../img/bg-clinics-finder-about.png) center center no-repeat #f4f3fe;
}
.about-section .layout {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}
.about-section .layout h2{
    font-family: "Baloo";
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 30px;
    color: #444444;
}
.about-section .layout h2 span{
	color: #8074f2;
}
.about-section .layout h4{
	font-family: "Baloo";
	font-size: 24px;
	font-weight: 500;
    line-height: 1.2;
	margin-bottom: 20px;
	color: #444444;
}
.about-section .layout p{
	font-size: 18px;
	font-weight: 400;
	line-height: 1.8;
	margin-bottom: 30px;
	color: #444444;
}
.about-section .paws_list{
	margin: 0;
}
.about-section .paws_list li {
    position: relative;
    padding-left: 26px;
    font-size: 18px;
	font-weight: 400;
    line-height: 1.4;
    color: #444444;
    margin-bottom: 20px;
    text-align: left;
}
.about-section .paws_list li:before {
    position: absolute;
    content: "";
    left: 0;
    top: 5px;
    width: 13px;
    height: 13px;
    background: url(../../img/paw.png) center center no-repeat;
    background-size: contain;
}
.about-section .layout .has-vivid-purple-color {
    color: #9b51e0;
}

@media screen and (max-width: 991px) {
	.about-section .layout h2 {
        margin-bottom: 10px;
	}
}

@media screen and (max-width: 767px) {
	.about-section {
	    background: #f7f7f7
	}
	.about-section .layout{
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	        -ms-flex-direction: column;
	            flex-direction: column;
    	text-align: center;
	}
	.about-section .layout p{
		font-size: 16px;
	    word-break: break-word;
	    text-align: left;
	}
	.about-section .paws_list li{
		font-size: 16px;
	}
}

@media screen and (max-width: 575px) {
	.about-section .layout h2 {
	    font-size: 22px;
	}
	.about-section .layout h4{
	    font-size: 18px;
    	margin-bottom: 8px;
	}
}

/* --------------------------- About section END ---------------------------------- */