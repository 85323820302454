/* --------------------------- Pagination single page START ---------------------------------- */

.pagination{
    padding: 40px 15px;
    position: relative;
}
.pagination ul{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: -20px -10px 0;
}
.pagination ul li{
	padding: 20px 10px 0;
}
.pagination ul li a,
.pagination ul li p{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	font-family: "Baloo";
	font-weight: 400;
	line-height: 1.2;
	color: #444;
	border-radius: 5px;
    -webkit-transition: all .2s;
    transition: all .2s;
}
.pagination ul li.default-button a,
.pagination ul li.default-button p{
	font-size: 20px;
    width: 40px;
    height: 40px;
}
.pagination ul li.default-button a:hover {
    background-color: #e3e0fd;
}
.pagination ul li.default-button.active p,
.pagination ul li.default-button.active a {
    background-color: #8074f2;
    color: #fff;
}
.pagination ul li.prev-button a,
.pagination ul li.next-button a{
	font-size: 16px;
    width: 85px;
    height: 40px;
    background-color: #e3e0fd;
}
.pagination.pagination-single-page ul li.prev-button a,
.pagination.pagination-single-page ul li.next-button a{
    width: auto;
    height: auto;
    padding: 10px 16px;
    min-height: 40px;
}
.pagination ul li.prev-button a:hover,
.pagination ul li.next-button a:hover {
    color: #444;
    -webkit-box-shadow: 0px 15px 32px 0px rgb(22 22 22 / 13%);
    box-shadow: 0px 15px 32px 0px rgb(22 22 22 / 13%);
}
.pagination ul li.prev-button svg,
.pagination ul li.next-button svg {
    display: inline-block;
    fill: #8074f2;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.pagination ul li.prev-button svg{
    margin-right: 7px;
}
.pagination ul li.next-button svg{
    margin-left: 7px;
}

@media screen and (max-width: 991px) {
	.pagination.pagination-city-category {
		margin-top: -50px;
	}
}

@media screen and (max-width: 767px) {
	.pagination.pagination-city-category ul{
		position: relative;
	    padding-bottom: calc(40px + 15px);
	}
	.pagination.pagination-city-category ul li.prev-button,
	.pagination.pagination-city-category ul li.next-button{
	    position: absolute;
    	top: calc(40px + 15px + 20px);
    	padding: 0;
	}
	.pagination.pagination-city-category ul li.prev-button {
	    left: 50%;
	    -webkit-transform: translateX(-100%) translateX(-5px);
	            transform: translateX(-100%) translateX(-5px);
	}
	.pagination.pagination-city-category ul li.next-button {
		right: 50%;
    	-webkit-transform: translateX(100%) translateX(5px);
    	        transform: translateX(100%) translateX(5px);
	}
}

@media screen and (max-width: 575px) {
	.pagination {
	    padding-top: 25px;
	    padding-bottom: 25px;
	}
	.pagination.pagination-single-page ul{
	    margin: -10px -5px 0;
	}
	.pagination.pagination-city-category ul{
	    margin: -4px -2px 0;
	    padding-bottom: calc(36px + 15px);
	}
	.pagination.pagination-single-page ul li{
		padding: 10px 5px 0;
	}
	.pagination.pagination-city-category ul li{
		padding: 4px 2px 0;
	}
	.pagination ul li.prev-button a,
	.pagination ul li.next-button a{
		font-size: 14px;
	    width: 70px;
	    height: 36px;
	}
	.pagination.pagination-single-page ul li.prev-button a,
	.pagination.pagination-single-page ul li.next-button a{
		padding: 9px 13px;
	    min-height: 36px;
	}
	.pagination.pagination-city-category ul li.prev-button,
	.pagination.pagination-city-category ul li.next-button{
	    top: calc(36px + 19px);
	}
	.pagination ul li.default-button a,
	.pagination ul li.default-button p{
	    font-size: 18px;
	    width: 36px;
	    height: 36px;
	}
}

/* --------------------------- Pagination single page END ---------------------------------- */
