/* --------------------------- Page title section START ---------------------------------- */

.page-title-section {
    background: url(../../img/bg-clinics-finder-title.png) center center no-repeat #f7f7f7;
    padding-top: 50px;
    padding-bottom: 120px;
    position: relative;
}

.page-title-section  .page-title {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    padding: 0 15px;
    max-width: 770px;
    width: 100%;
    margin: 0 auto;
}

.page-title-section .page-title > img {
    display: block;
    width: 75px;
    height: 75px;
    -o-object-fit: contain;
       object-fit: contain;
}

.page-title-section .page-title h1{
    font-family: Baloo;
    font-weight: 500;
	font-size: 50px;
    color: #444444;
    line-height: 1.2;
}

.page-title-section .enter-zip {
    font-size: 18px;
	font-weight: 400;
    line-height: 1.8;
    color: #444444;
    margin-bottom: 36px;
}

.page-title-section .form-group {
    width: 100%;
    max-width: 570px;
    height: 60px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.page-title-section .input-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
}

.page-title-section .input-wrapper input {
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	cursor: pointer;
    border-radius: 5px;
    padding: 10px 30px;
    border: 1px solid #e3e0fc;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    -webkit-font-variant-ligatures: none;
            font-variant-ligatures: none;
    padding-right: 170px;
    padding-left: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: #000000;
}

.page-title-section .input-wrapper input:focus {
    border-color: #8074f2;
}

.page-title-section .input-wrapper input::-webkit-input-placeholder {
    color: #000000;
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.page-title-section .input-wrapper input::-moz-placeholder {
    color: #000000;
    opacity: 1;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.page-title-section .input-wrapper input:-ms-input-placeholder {
    color: #000000;
    opacity: 1;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.page-title-section .input-wrapper input:-moz-placeholder {
    color: #000000;
    opacity: 1;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.page-title-section .input-wrapper input::-webkit-outer-spin-button,
.page-title-section .input-wrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-title-section .input-wrapper input[type=number] {
	-moz-appearance: textfield;
}

.page-title-section .input-wrapper p {
    position: absolute;
    z-index: 1;
    color: #222222;
    font-size: 14px;
    font-weight: 400;
    top: 18px;
    left: 30px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    display: block;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: #fff;
    line-height: 1.8;
    left: 50px;
}

.page-title-section .input-wrapper p.input-fill {
	top: -10px;
	font-size: 12px;
	color: #8074f2;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.page-title-section button{
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
    font-family: Baloo;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    background-color: #8074f2;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    width: 150px;
    right: 0;
    top: 0;
    height: 100%;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-appearance: button;
    cursor: pointer;
    outline: none;
}

.page-title-section button:hover {
    background-color: #79a3ef;
    color: #fff;
}

.page-title-section .form-group__icon {
    position: absolute;
    left: 30px;
    top: 50%;
    opacity: 1;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.form-group__icon svg {
    display: block;
    width: 10.5px;
    height: 15px;
    fill: #8074f2;
}

@media screen and (max-width: 1399px) {
	.page-title-section .page-title h1 {
		font-size: 44px;
	}
}

@media screen and (max-width: 1199px) {
	.page-title-section .page-title h1 {
	    font-size: 45px;
	}
}

@media screen and (max-width: 991px) {
	.page-title-section .page-title {
	    padding: 0;
	}
}

@media screen and (max-width: 767px) {
	.page-title-section {
	    padding-bottom: 90px;
	}
	.page-title-section .page-title h1 {
	    font-size: 35px;
	}
	.page-title-section .enter-zip {
		word-break: break-word;
	}
	.page-title-section .input-wrapper p {
	    word-break: break-word;
	}
	.page-title-section button {
	    font-size: 14px;
	}
}

@media screen and (max-width: 575px) {
	.page-title-section .page-title h1 {
		font-size: 24px;
	}
	.page-title-section .form-group {
	    height: 46px;
    	font-size: 12px;
	}
	.page-title-section .input-wrapper p {
		top: 11px;
	}
	.page-title-section button {
	    font-size: 12px;
    	width: 100px;
	}
	.page-title-section .input-wrapper input {
		font-size: 12px;
	}
}

/* --------------------------- Page title section END ---------------------------------- */