/* --------------------------- Eat list START ---------------------------------- */

@media screen and (min-width: 992px) {
    .eat-list .container {
        max-width: 1200px;
    }
}
@media screen and (min-width: 1400px) {
    .eat-list .container {
        max-width: 1830px;
    }
}
.eat-list {
    padding: 70px 0 30px;
    position: relative;
}
.eat-list .layout {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}
.eat-list__items {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.eat-list__items .items__wrap {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.eat-list__items .items__wrap .item {
    width: calc(100% / 6);
    padding-right: 30px;
    margin-bottom: 40px;
}
.eat-list .letter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    position: relative;
    background: #fdf5d1;
    width: 60px;
    height: 60px;
    font-family: "Baloo";
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 25px;
    line-height: 1;
}
.eat-list .letter:before {
    position: absolute;
    content: "";
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border-radius: 50%;
    border-width: 2px;
    border-color: #fece00;
    border-style: dotted;
}
.eat-list__items .items__wrap .item ul li {
    margin-bottom: 12px;
}
.eat-list__items .items__wrap .item ul li a {
    font-size: 18px;
    font-weight: 400;
    color: #444444;
    line-height: 1.4;
    text-decoration: none;
}
.eat-list__items .alphabet {
    /*display: none;*/
    /*position: sticky;*/
    /*top: 20px;*/
    /*right: 0;*/
    /*width: 30px;*/
    /*height: auto;*/
    /*margin-bottom: auto;*/
}
.eat-list__items .alphabet li {
    /*margin-bottom: 4px;*/
}
.eat-list__items .alphabet li a {
    /*display: block;*/
    /*text-align: center;*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*font-weight: 500;*/
    /*color: #444444;*/
    /*text-transform: uppercase;*/
    /*font-size: 12px;*/
    /*text-decoration: none;*/
}
.eat-list__items .alphabet li a.active,
.eat-list__items .alphabet li.active a {
    /*font-weight: 700;*/
    /*color: #8074f2;*/
}

@media screen and (max-width: 991px) {
    .eat-list__items .items__wrap .item {
        width: calc(100% / 4);
        padding-right: 20px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 767px) {
    .eat-list__items .items__wrap .item ul li a{
        font-size: 16px;
    }
}

@media screen and (max-width: 575px) {
    .eat-list__items .items__wrap {
        width: calc(100% - 30px);
    }
    .eat-list__items .items__wrap .item {
        width: 100%;
        padding-right: 30px;
        margin-bottom: 30px;
    }
    .eat-list .letter {
        display: block;
        border-radius: unset;
        background: none;
        width: auto;
        height: auto;
        font-family: "Baloo";
        font-size: 20px;
        margin-bottom: 5px;
        color: #8074f2;
    }
    .eat-list .letter:before{
        display: none;
    }
    .eat-list__items .alphabet {
        /*display: block;*/
    }
}

/* --------------------------- Eat list END ---------------------------------- */
